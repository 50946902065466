import React, { useEffect, useState } from 'react';
import LivePoster3 from './LiveTV/LivePoster3';
export default function LiveTv() {
    let isEmpty = false;
    const [data, setData] = useState([]);
    useEffect(() => {
        document.body.style.height = "100vh";
        fetch('https://streamhaven.xyz/api/liveTv.php')
            .then(response => response.json())
            .then(jsonData => {
                if (jsonData["result"] === "null") {
                    isEmpty = true;
                    setData([]);
                } else {
                    setData(jsonData);
                }
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);
    const renderTags = () => {
        return (
            data.map((item) => (<LivePoster3 name={item.name} link={`https://streamhaven.xyz/api/watch.php?live_id=${item.streamId}`}/>))
        );
        };
    return (
        <>
            {!isEmpty ? renderTags() : <h2 className='noLive'>پخش زنده‌یی نیست فعلا</h2>}
        </>
    )
}
