import React from 'react'
import './Sidebar.css';
import MenuItem from './MenuItem';
import { useState } from 'react';
import { useEffect } from 'react';
import LiveGames from '../LiveGames';
import TomGames from '../TomGames';
import TodayGames from '../TodayGames';
import Highlights from '../Highlights';
import LiveTv from '../LiveTv';


export default function MySideBar() {
    const [activeComponent, setActiveComponent] = useState('LiveGames');
    const [isApp,setIsApp] = useState(false);
    useEffect(() => {
        fetch('https://streamhaven.xyz/api/isApp.php')
        .then(response => {
            if (response.status === 200) {
                setIsApp(true);
            } else {
                setIsApp(false);
            }
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    const renderContent = () => {
        switch (activeComponent) {
            case 'LiveGames':
                return <LiveGames />;
            case 'TodayGames':
                return <TodayGames />;
            case 'TomorrowGames':
                return <TomGames />;
            case 'Highlights':
                return <Highlights />;
            default:
                return <LiveTv />;
        }
    };

    return (
        <div className='container'>
            <div className='sidebar'>
                <img className='logo' src="./Logo.png"/>
                    <MenuItem title="بازی های زنده" onClick={() => setActiveComponent('LiveGames') } isActive={activeComponent === 'LiveGames'} />
                    <MenuItem title="بازی های امروز" onClick={() => setActiveComponent('TodayGames')} isActive={activeComponent === 'TodayGames'} />
                    <MenuItem title="بازی های فردا" onClick={() => setActiveComponent('TomorrowGames')} isActive={activeComponent === 'TomorrowGames'} />
                    <MenuItem title="خلاصه بازی ها" onClick={() => setActiveComponent('Highlights')} isActive={activeComponent === 'Highlights'} />
                    <MenuItem title="پخش زنده شبکه ها" onClick={() => setActiveComponent('LiveTv')} isActive={activeComponent === 'LiveTv'} />
                    {isApp ? "" : <a href="https://streamhaven-xyz.translate.goog/?_x_tr_sl=auto&_x_tr_tl=fa&_x_tr_hl=en&_x_tr_pto=wapp" className='language'><svg className='redirect' xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M216-144q-29.7 0-50.85-21.15Q144-186.3 144-216v-528q0-29.7 21.15-50.85Q186.3-816 216-816h264v72H216v528h528v-264h72v264q0 29.7-21.15 50.85Q773.7-144 744-144H216Zm171-192-51-51 357-357H576v-72h240v240h-72v-117L387-336Z"/></svg>Change Language</a>}
            </div>
            <div className='content'>
                {renderContent()}
            </div>
            
        </div>
    )
}
