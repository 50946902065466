import React from 'react';
import ReactDOM from 'react-dom/client';
import MySideBar from './SideBar/MySideBar';  // Make sure this file exists and is correctly exported

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <MySideBar />
);