import React from 'react'
import './Poster.css';
export default function LivePoster1({name,link}) {
    return (
            <div className='poster'>
                <a href={link} target="_blank">
                    <svg
                        className='svg'
                        id="visual"
                        viewBox="0 0 900 600"
                        width="20rem"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        version="1.1"
                        >
                        <rect x="0" y="0" width="900" height="600" fill="#002233" />
                        <path
                            d="M0 398L21.5 414.8C43 431.7 86 465.3 128.8 476.5C171.7 487.7 214.3 476.3 257.2 473.2C300 470 343 475 385.8 468.8C428.7 462.7 471.3 445.3 514.2 446.7C557 448 600 468 642.8 481.5C685.7 495 728.3 502 771.2 489.2C814 476.3 857 443.7 878.5 427.3L900 411L900 601L878.5 601C857 601 814 601 771.2 601C728.3 601 685.7 601 642.8 601C600 601 557 601 514.2 601C471.3 601 428.7 601 385.8 601C343 601 300 601 257.2 601C214.3 601 171.7 601 128.8 601C86 601 43 601 21.5 601L0 601Z"
                            fill="#0066FF"
                            strokeLinecap="round" 
                            strokeLinejoin="miter"
                        />
                        <text
                            x="50%"
                            y="50%"
                            dominantBaseline="middle"
                            textAnchor="middle"
                            fill="#fff"
                            fontSize="100" 
                            fontFamily="inherit"
                        >
                            {name}
                        </text>
                    </svg>
                </a>
            </div>
    )
}
